import { render, staticRenderFns } from "./ButtonArchiver.vue?vue&type=template&id=651079d4&scoped=true"
import script from "./ButtonArchiver.vue?vue&type=script&lang=js"
export * from "./ButtonArchiver.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "651079d4",
  null
  
)

export default component.exports